import buildBlock from './build-block'
import layoutComponentsConfig from './form-layout/layout-components-config'

/**
 * 递归找到相应的控件
 * @param array
 * @param fieldNames
 * @param callback
 */
const traverse = (array = [], fieldNames, callback) => {
  array.forEach(element => {
    if (fieldNames.has(element.model)) {
      callback(element)
    }

    const recursion = (element, layoutCfg) => {
      if (layoutCfg.subNode) {
        element[layoutCfg.subNode.itemKey].forEach(ele => recursion(ele, layoutCfg.subNode))
      } else traverse(element.list, fieldNames, callback)
    }

    if (layoutComponentsConfig[element.type]) {
      recursion(element, layoutComponentsConfig[element.type])
    }
  })
}

export default {
  props: {
    formBuilder: {
      type: Object,
      default: () => ({})
    },
    dataRangeKeys: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    buildBlock
  },
  data () {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: (props, values) => {
          // Object.assign(this.formBuilder.formValues, values)
          this.formBuilder.formValues = { ...this.formBuilder.formValues, ...values }
        }
      })
    }
  },
  mounted () {
    const formId = this.formBuilder?.formConfig?.formId || 'axForm'
    this.$ax.$on(`${formId}_setFieldsValue`, formValues => {
      this.setFieldsValue(formValues)
    })
    this.$ax.$on(`${formId}_setOptions`, (fieldNames, optionProp, value) => {
      this.setOptions(fieldNames, optionProp, value)
    })
    this.$ax.$on(`${formId}_setFormItem`, (fieldNames, formItemProp, value) => {
      this.setFormItem(fieldNames, formItemProp, value)
    })
    this.$ax.$on(`${formId}_setRules`, rules => {
      this.setRules(rules)
    })
    this.$ax.$on(`${formId}_setFormItemProp`, (fieldName, props) => {
      this.setFormItemProp(fieldName, props)
    })
    this.$ax.$on(`${formId}_setColOptions`, (fieldName, props) => {
      this.setColOptions(fieldName, props)
    })
  },
  methods: {
    /**
     * 给form赋值
     * @param formValues
     */
    setFieldsValue (formValues = {}) {
      const { form: { getFieldsValue, setFieldsValue } } = this
      this.formBuilder.formValues = { ...this.formBuilder.formValues, ...formValues }
      this.$nextTick(() => {
        const values = getFieldsValue()
        Object.keys(values).forEach(key => {
          if (Object.keys(formValues).includes(key)) {
            values[key] = formValues[key]
          }
        })
        setFieldsValue(values)
      })
    },
    /**
     * 获取表单的值
     * @param fieldName
     * @returns {*}
     */
    getFieldsValue (fieldName) {
      const { form: { getFieldsValue, getFieldValue } } = this
      if (!fieldName) return { ...this.formBuilder.formValues, ...getFieldsValue() }
      else if (Array.isArray(fieldName)) return getFieldsValue(fieldName)
      else return getFieldValue(fieldName)
    },
    /**
     * 重置一组输入控件的值（为 initialValue）与状态，如不传入参数，则重置所有组件
     * @param fieldName
     */
    resetFields (fieldName) {
      const { form: { resetFields, getFieldsValue } } = this
      resetFields(fieldName)
      const formValues = getFieldsValue()
      this.formBuilder.formValues = { ...this.formBuilder.formValues, ...formValues }
    },
    /**
     * 批量设置某个option的值
     * @param fieldNames
     * @param optionProp
     * @param value
     */
    setOptions (fieldNames, optionProp, value) {
      if (fieldNames === 'all') {
        fieldNames = new Set(Object.keys(this.form.getFieldsValue()))
      } else fieldNames = new Set(fieldNames)
      const callbackFn = element => {
        if (this.$ax.tools.isEmpty(element.options)) this.$set(element, 'options', {})
        this.$set(element.options, optionProp, value)
      }
      traverse(this.formBuilder.list, fieldNames, callbackFn)
    },
    /**
     * 手动设置formItem的属性值
     * @param fieldNames
     * @param formItemProp
     * @param value
     */
    setFormItem (fieldNames, formItemProp, value) {
      if (fieldNames === 'all') {
        fieldNames = new Set(Object.keys(this.form.getFieldsValue()))
      } else fieldNames = new Set(fieldNames)
      const callbackFn = element => {
        if (this.$ax.tools.isEmpty(element.formItem)) this.$set(element, 'formItem', {})
        this.$set(element.formItem, formItemProp, value)
      }
      traverse(this.formBuilder.list, fieldNames, callbackFn)
    },
    /**
     * 手动设置验证规则
     * @param rules
     */
    setRules (rules) {
      const fieldNames = new Set(Object.keys(rules))
      const callbackFn = element => this.$set(element, 'rules', rules[element.model])
      traverse(this.formBuilder.list, fieldNames, callbackFn)
    },
    /**
     * 手动设置控件的某个属性
     * @param fieldName
     * @param props
     */
    setFormItemProp (fieldName, props) {
      fieldName = new Set([fieldName])
      const callbackFn = element => Object.assign(element, props)
      traverse(this.formBuilder.list, fieldName, callbackFn)
    },
    /**
     * 手动设置控件的col属性
     * @param fieldName
     * @param props
     */
    setColOptions (fieldName, props) {
      fieldName = new Set([fieldName])
      this.formBuilder.list.forEach(row => {
        row.cols.forEach(col => {
          if (fieldName.has(col.model)) Object.assign(col, props)
        })
      })
    },
    /**
     * 设置起始时间的一个禁用范围
     * @param value
     * @param key
     */
    change (value, key) {
      const dataRangeKeys = [...new Set(this.dataRangeKeys.map(dateRange => {
        return Object.values(dateRange).join(',')
      }).join(',').split(',').filter(item => item))]
      if (dataRangeKeys.includes(key)) {
        this.dataRangeKeys.forEach(dateRange => {
          const { startKey, endKey, equal = false, format = 'YYYY-MM-DD HH:mm:ss' } = dateRange
          if (startKey === key) {
            this.setOptions([endKey], 'disabledDate', val => {
              return this.$ax.dateUtil.getDisabledDate(val, this.formBuilder.formValues[startKey], equal ? '<' : '<=', format)
            })
          } else if (endKey === key) {
            this.setOptions([startKey], 'disabledDate', val => {
              return this.$ax.dateUtil.getDisabledDate(val, this.formBuilder.formValues[endKey], equal ? '>' : '>=', format)
            })
          }
        })
      }
    }
  }
}
